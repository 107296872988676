import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import user_icon from "../../../../assets/vector-images/generic/user-icon.svg";
import users_icon from "../../../../assets/vector-images/panel/users.svg";
import { TableColumn } from "../../../../components/layout/Table/types";
import { Card, PageHeader, Table } from "../../../../components/layout";
import { accountPaths } from "../../../../routes/accounts/paths";
import { StatsCard } from "../../../../components/layout/Card";
import { useQueryParams } from "../../../../utils/components";
import { User } from "../../../../requests/users/types";
import { PaginatedResponse } from "../../../../types";
import useUsers from "../../../../requests/users";

function UserList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const orderBy = queryParams.get("orderBy") || "";
  const { listUsers } = useUsers();
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<PaginatedResponse<User>>();
  const [stats, setStats] = useState<StatsCard[]>([]);

  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      orderByKey: "firstName",
      content: (user: User) => (
        <Link to={accountPaths.userDetail(user.id)} className="table-title">
          <img alt="" src={user.avatar?.url ? user.avatar.url : user_icon} />
          <div>
            {user.firstName} {user.lastName}
          </div>
          <div className={`status ${user.familyRole}`}>
            {user.familyRole === "admin" ? "Parent" : "Learner"}
          </div>
        </Link>
      ),
    },
    {
      title: "Email / Username",
      key: "username",
      orderByKey: "username",
    },
    {
      title: "Archived",
      key: "archived",
      orderByKey: "archived",
      content: (user: User) => (user.archived ? "Yes" : "No"),
    },
    {
      title: "Active",
      key: "active",
      orderByKey: "active",
      content: (user: User) => (user.active ? "Yes" : "No"),
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (user: User) =>
        moment(user.createdAt).format("Do MMM YYYY, hh:mma"),
    },
    {
      title: "Last Login",
      key: "lastLogin",
      orderByKey: "lastLogin",
      content: (user: User) =>
        user.lastLogin
          ? moment(user.lastLogin).format("Do MMM YYYY, hh:mma")
          : "Has not signed-in yet",
    },
    {
      title: "Updated At",
      key: "updatedAt",
      orderByKey: "updatedAt",
      content: (user: User) =>
        moment(user.updatedAt).format("Do MMM YYYY, hh:mma"),
    },
  ];

  /******************/
  /** Set card data */
  const upsertStat = (stat: StatsCard) => {
    setStats((prevStats) => {
      const existingStatIndex = prevStats.findIndex(
        (item) => item.title === stat.title
      );
      if (existingStatIndex > -1) {
        // Update Stat
        const updatedStats = [...prevStats];
        updatedStats[existingStatIndex] = stat;
        return updatedStats;
      } else {
        // Add New Stat
        return [...prevStats, stat];
      }
    });
  };

  /*****************/
  /** Get all data */
  const syncData = () => {
    setLoading(true);
    // UPDATING USER TYPES TO SUIT BACKEND
    // const userTypes = ["primary", "secondary", "basic"];
    const userTypes = ["basic"];
    const params = {
      search,
      userType: userTypes.join(),
      page,
      pageSize,
      orderBy,
    };
    // Get User List
    listUsers(params)
      .then((data) => {
        setTableData(data);
        upsertStat({
          title: "Total Users",
          content: <div className="value main">{data.count}</div>,
        });
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    syncData();
  }, [locationSearch]);

  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader showSearch={true} />

      {/* Main Content */}
      <div className="page-wrapper gap-1">
        <div className="grid">
          {stats.map((stat, index) => (
            <Card key={index}>
              <div className="card-title">{stat.title}</div>
              {stat.content}
            </Card>
          ))}
        </div>
        <Table
          {...tableData}
          columns={columns}
          loading={loading}
          noData={{
            icon: users_icon,
            title: "No User Accounts",
          }}
        />
      </div>
    </div>
  );
}

export default UserList;
